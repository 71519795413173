



























import {Component, Mixins} from 'vue-property-decorator';

import {api} from '@/shared/api';
import {like} from '@/shared/like';
import RegisterEmployee from '@/employees/RegisterEmployee.vue';
import {Nullable} from '@/shared/types/Nullable';
import {InputAttrs} from '@/shared/mixins/InputAttrs';
import {GraphUser} from '@/graph/GraphUser';

@Component({
    components: {
        RegisterEmployee,
    },
})
export default class AdImport extends Mixins(InputAttrs) {
    protected isFetching = false;
    protected users: GraphUser[] = [];
    protected selectedUser: Nullable<GraphUser> = null;
    protected error: Nullable<string> = null;

    async fetch(): Promise<void> {
        try {
            const response = await api<GraphUser[]>({
                url: 'Graph/users/unregistered',
            });

            if (response && response.data) {
                this.users = response.data;
            }
        } catch (e) {
            this.error = e.message;
        }
    }

    private term = '';

    async fetchList(): Promise<void> {
        if (this.users.length === 0) {
            this.error = null;
            this.isFetching = true;
            await this.fetch();
            this.isFetching = false;
        }
    }

    get filteredEmployees(): GraphUser[] {
        if (this.term === '') {
            return this.users;
        } else {
            const likeTerm = like(this.term);

            return this.users.filter((user) => {
                return likeTerm(user.displayName);
            });
        }
    }
}
