






import {Component, Vue} from 'vue-property-decorator';
import {MetaInfo} from 'vue-meta';

import AllEmployees from '@/employees/AllEmployees.vue';
import AppScreen from '@/layout/AppScreen.vue';

@Component({
    components: {
        AppScreen,
        AllEmployees,
    },
    metaInfo(this: EmployeesScreen): MetaInfo {
        return {
            title: `${this.$t('menuEmployees')} — HERMES`,
        };
    },
})
export default class EmployeesScreen extends Vue {

}
