





















































import {Component, InjectReactive, Mixins} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import debounce from 'lodash.debounce';

import {HermesUser, MeUser} from '@/employees/store/HermesUser';
import EmployeeShortCard from '@/employee/employeeDetails/EmployeeShortCard.vue';
import AdImport from '@/graph/AdImport.vue';
import ColWidth from '@/shared/mixins/ColWidth';

const employees = namespace('employees');

@Component({
    components: {
        AdImport,
        EmployeeShortCard,
    },
})
export default class AllEmployees extends Mixins(ColWidth) {
    @employees.Getter('filteredEmployees') employees!: HermesUser[];
    @employees.State('list') allUsers!: HermesUser[];
    @employees.State showTerminated!: boolean;
    @employees.State isFetching!: boolean;
    @employees.State quickSearch!: string;
    @employees.Mutation setSearch!: Function;
    @employees.Mutation setShowTerminated!: Function;
    @employees.Action('list') fetchAll!: Function;

    @InjectReactive()
    readonly viewer!: MeUser;

    searchValue = '';

    async mounted(): Promise<void> {
        this.searchValue = this.$route.query.userSearch as string || '';

        await this.fetchAll();
    }

    onSearchInput(): void {
        if (this.searchValue) {
            this.pushSearchValue(this.searchValue);
        }
    }

    onSearchEnter(event: KeyboardEvent): void {
        this.pushSearchValue((event.target as HTMLInputElement).value);
    }

    onSearchClear(): void {
        this.pushSearchValue('');
    }

    _pushSearchValue(value: string): void {
        const name = this.$route.name as string;
        if (value) {
            this.$router.push({
                name,
                query: {
                    userSearch: value,
                },
            });
        } else {
            this.$router.push({
                name,
            });
        }
    }

    pushSearchValue = debounce(this._pushSearchValue, 300);
}
