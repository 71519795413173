































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {namespace} from 'vuex-class';

import ContactEmployeeBtn from '@/employee/employeeDetails/ContactEmployeeBtn.vue';
import {GraphUser} from '@/graph/GraphUser';
import {IsFullscreen} from '@/shared/mixins/IsFullscreen';
import AppDialog from '@/shared/AppDialog.vue';

const employees = namespace('employees');

@Component({
    components: {
        AppDialog,
        ContactEmployeeBtn,
    },
})
export default class RegisterEmployee extends Mixins(IsFullscreen) {
    @Prop(Object)
    readonly user!: GraphUser;

    @employees.Action register!: Function;
    @employees.State error!: string;
    @employees.Mutation setError!: Function;

    public async registerEmployee(): Promise<void> {
        const {id} = await this.register(this.user.id);
        this.$emit('cancel');
        await this.$router.push({name: 'employeeDetails', params: {id}});
    }

    onCancelClick(): void {
        this.setError('');
        this.$emit('cancel');
    }
}
