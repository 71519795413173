













import {Component, Vue, Prop} from 'vue-property-decorator';
import {HermesUser} from '@/employees/store/HermesUser';
import {Nullable} from '@/shared/types/Nullable';
import {extractTel} from '@/shared/extractTel';

@Component
export default class ContactEmployeeBtn extends Vue {
    @Prop({default: false})
    readonly dark!: boolean;

    @Prop(Object)
    readonly user!: HermesUser;

    @Prop(String)
    readonly proto!: string;

    get value(): Nullable<string> {
        if (this.proto === 'mailto') {
            return this.user.email;
        } else if (this.proto === 'sip') {
            return this.user.adAccount;
        } else if (this.proto === 'tel') {
            return extractTel(this.user);
        } else {
            return null;
        }
    }

    get icon(): Nullable<string> {
        if (this.proto === 'mailto') {
            return 'alternate_email';
        } else if (this.proto === 'sip') {
            return 'message';
        } else if (this.proto === 'tel') {
            return 'phone';
        } else {
            return null;
        }
    }

    get label(): string {
        let labelKey = '';
        if (this.proto === 'mailto') {
            labelKey = 'mailUser';
        } else if (this.proto === 'sip') {
            labelKey = 'skypeUser';
        } else if (this.proto === 'tel') {
            labelKey = 'callUser';
        }

        return this.$t(labelKey, {value: this.value}) as string;
    }

    get href(): string {
        return `${this.proto}:${this.value}`;
    }
}
